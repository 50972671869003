require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({12:[function(require,module,exports){
"use strict";

require("./_components/jquery-global.js");

//	https://docs.ckeditor.com/ckeditor5/latest/framework/guides/quick-start.html

// read product record based on given ID
$(document).ready(function () {
	init();
});

function init() {

	$(document).on("scroll", function () {
		if ($(document).scrollTop() > 400 && window.innerWidth > 800) {
			$("#topnav-cont").addClass("float");
			$("#topnav-cont .topbar").addClass("float");
			$("#topnav-cont .mainbar").addClass("float");
			$("#topnav-cont .topbar .left-side").addClass("float");
			$("#topnav-cont .topbar .right-side").addClass("float");
		} else {
			$("#topnav-cont").removeClass("float");
			$("#topnav-cont .topbar").removeClass("float");
			$("#topnav-cont .mainbar").removeClass("float");
			$("#topnav-cont .topbar .left-side").removeClass("float");
			$("#topnav-cont .topbar .right-side").removeClass("float");
		}
	});

	$('.search-input').on('focus', function () {
		if ($(this).val() === "SEARCH") {
			$(this).val('');
		}
	});
	$('.search-input').on('blur', function () {
		if ($(this).val() === "") {
			$(this).val('SEARCH');
		}
	});

	$('#admin-debug-window-toggle-btn').on('click', function (event) {
		// console.log( document.getElementById('admin-debug-float-window').style.right );
		var pos = document.getElementById('admin-debug-float-window').style.right;
		var rightTo = "0%";
		if (pos == '-35%') {
			rightTo = "0%";
		} else {
			rightTo = "-35%";
		}
		// Animate
		$('#admin-debug-float-window').animate({
			right: rightTo
		}, 500, function () {
			// Animation complete.
		});
	});

	$('a[rel=external-new-window]').click(function () {
		window.open(this.href, "ShareOnFacebook", "width=550, height=540");
		return false;
	});
}

},{"./_components/jquery-global.js":5}]},{},[12]);
